import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import { NavArtSpaceLogo } from "../../../theme/NavArtSpaceLogo";
import { HamburgerIcon } from "../../../theme/HamburgerIcon";
import { CloseIcon } from "../../../theme/CloseIcon";
import { VimeoIcon } from "../../../theme/VimeoIcon";
import { InstagramIcon } from "../../../theme/InstagramIcon";
import HeaderNav from "../HeaderNav";
import Headroom from "react-headroom";
import { SocialLinks } from "../SocialLinks";
import "./Header.scss";
import loadable from "@loadable/component";
import GatsbyLink from "../GatsbyLink";
import { decodeEntities } from "../../utils/helpers";

const isClient = typeof window !== "undefined";

const QuoteCart = (props) => {
  const { options } = props;
  const { quote, showQuoteButtonInHeader, quotePage } = options;
  if (quote && showQuoteButtonInHeader) {
    const QuoteCartCount = loadable(() => import(`../Quote/QuoteCartCount.js`));
    return (
      <div className="quote-cart">
        <GatsbyLink to={quotePage}>
          <QuoteCartCount />
        </GatsbyLink>
      </div>
    );
  }
  return null;
};

class Header extends Component {
  state = {
    contactActive: false,
    navActive: false,
    hoverClear: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if (isClient) {
        let childrenDomElements = document.querySelectorAll(".children");
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains("open")) {
            childrenDomElements[i].classList.remove("open");
          }
        }
        if (document.body.classList.contains("nav-open")) {
          this.toggleNav();
        }
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleNav = (event) => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add("nav-open");
      }
      this.setState({
        contactActive: false,
        navActive: true,
      });
    } else {
      if (isClient) {
        document.body.classList.remove("nav-open");
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = (event) => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    this.setState({
      navActive: false,
    });
  };

  resetHoverClear = (event, value) => {
    this.setState({ hoverClear: true, navActive: false });
    if (isClient) {
      document.body.classList.remove("nav-open");
    }
    setTimeout(() => {
      this.setState({ hoverClear: false });
    }, 1000);
  };

  render() {
    const { navActive, hoverClear } = this.state;
    const { data } = this.props;
    const {
      abovePrimaryNavigation,
      primaryNavigation,
      siteSettings,
      metaData,
      wordpressWpSettings,
    } = data;
    const { options } = siteSettings;
    const { quote, showQuoteButtonInHeader, quotePage } = options;
    const { phone, email } = options.locations[0];
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation;
    const { items: aboveNavItems } = abovePrimaryNavigation;

    return (
      <Headroom disableInlineStyles={true} pinStart={50}>
        <header className={options.invertHeaderColours ? "invert" : ""}>
          {options.navigationAbovePrimaryMenu && (
            <div className="above-header">
              <div className="inner">
                {options.contactNumberHeader && (
                  <div className="phone">
                    <a
                      href={`tel:${phone.replace(/[^A-Z0-9]/gi, "")}`}
                      className="control-call"
                      aria-label="Call Now"
                    >
                      {phone}
                    </a>
                  </div>
                )}
                {options.socialLinksHeader && <SocialLinks />}
                <HeaderNav
                  active={navActive}
                  className="above-head-navigation"
                  resetHoverClear={this.resetHoverClear}
                  hoverClear={hoverClear}
                  navItems={aboveNavItems}
                  baseUrl={baseUrl}
                  toggleNav={this.toggleNav}
                />
              </div>
            </div>
          )}

          <div className="header-main">
            <div className="wrap">
              <div className="inner">
                {!navActive && (
                  <div className="top-links">
                    <GatsbyLink
                      className={`nav-link-to-home`}
                      to="/"
                      aria-label="Link to Works page"
                    >
                      HOME
                    </GatsbyLink>
                    <GatsbyLink
                      className={`nav-link-to-works-page`}
                      to="/works/"
                      aria-label="Link to Works page"
                    >
                      WORKS
                    </GatsbyLink>

                    <GatsbyLink
                      className={`nav-link-to-the-artist-page`}
                      to="/the-artist/"
                      aria-label="Link to Artist page"
                    >
                      ARTIST
                    </GatsbyLink>
                    <NavArtSpaceLogo />
                    <GatsbyLink
                      className={`nav-link-to-the-news-page`}
                      to="/news-and-media/"
                      aria-label="Link to News page"
                    >
                      NEWS
                    </GatsbyLink>
                  </div>
                )}
                <div className="nav">
                  <nav className={`nav-inner${navActive ? " active" : ""}`}>
                    <a className="top-menu-link button--light" href="#">
                      Menu
                    </a>
                    <HeaderNav
                      active={navActive}
                      className="navigation"
                      navItems={navItems}
                      resetHoverClear={this.resetHoverClear}
                      hoverClear={hoverClear}
                      baseUrl={baseUrl}
                      toggleNav={this.toggleNav}
                    />
                    <div className="bottom-menu-links">
                      <div className="bottom-menu-links--email"><a href={`mailto:${decodeEntities(email)}`}>EMAIL</a></div>
                      <div className="bottom-menu-links--follow">FOLLOW:</div>
                      <div className="bottom-menu-links--icon-wrapper">
                        <InstagramIcon className="bottom-menu-links--icon instagram-icon" />
                        <VimeoIcon className="bottom-menu-links--icon vimeo-icon" />
                      </div>
                    </div>
                  </nav>
                  <div className="controls">
                    {options.showSearchIconInMainNavigation && (
                      <GatsbyLink
                        className={`control-search icon-search`}
                        to="/search/"
                        aria-label="Toggle Search"
                      />
                    )}
                    <QuoteCart options={options} />

                    <button
                      type="button"
                      className={`control-nav icon-hamburger ${
                        navActive ? "active" : ""
                      }`}
                      onClick={this.toggleNav}
                      aria-label="Toggle Menu"
                    >
                      {navActive ? <CloseIcon /> : <HamburgerIcon />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "above-primary-navigation" }
        ) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            # children: wordpress_children {
            #   title
            #   url
            #   classes
            #   object_id
            #   slug: object_slug
            #   target
            # }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            # children: wordpress_children {
            #   title
            #   url
            #   classes
            #   object_id
            #   slug: object_slug
            #   target
            # }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            navigationAbovePrimaryMenu
            socialLinksHeader
            contactNumberHeader
            invertHeaderColours
            showSearchIconInMainNavigation
            locations {
              addressDisplay
              email
              phone
            }
            # quote
            # quotePage
            # showQuoteButtonInHeader
            # quotePage
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
);
