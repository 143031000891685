import React from "react";

export const HamburgerIcon = (props) => {
  return (
    <svg
      id="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="256.95364238410593"
      viewBox="0, 0, 400,256.95364238410593"
      version="1.1"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M42.907 38.370 C 39.610 38.833,36.168 41.582,34.407 45.158 C 31.459 51.146,35.349 58.537,42.718 60.951 L 44.602 61.568 205.013 61.568 C 384.314 61.568,367.513 61.748,371.648 59.784 C 381.615 55.051,379.877 41.217,368.995 38.669 C 367.082 38.221,46.066 37.926,42.907 38.370 M348.560 112.955 C 348.475 113.046,280.794 113.117,198.157 113.113 C 63.901 113.107,47.666 113.148,45.634 113.503 C 33.350 115.645,29.238 126.334,37.755 133.984 C 39.335 135.402,41.206 136.170,43.935 136.519 C 46.589 136.859,364.751 136.830,367.224 136.489 C 381.128 134.576,382.242 116.830,368.636 113.993 C 366.935 113.638,364.001 113.449,352.442 112.950 C 350.392 112.862,348.645 112.864,348.560 112.955 M151.212 189.242 C 147.597 189.565,143.762 192.456,141.882 196.275 C 139.379 201.360,142.168 207.850,148.055 210.640 L 149.743 211.440 257.841 211.508 C 363.003 211.574,368.692 211.539,371.212 210.811 C 379.442 208.429,380.554 194.461,372.811 190.713 C 369.755 189.233,378.411 189.345,261.954 189.282 C 203.207 189.251,154.679 189.191,154.113 189.150 C 153.548 189.108,152.242 189.150,151.212 189.242 "
          stroke="none"
          fill="#202020"
          fillRule="evenodd"
        />
        <path
          id="path1"
          d="M0.000 128.535 L 0.000 257.069 200.000 257.069 L 400.000 257.069 400.000 128.535 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 128.535 M368.995 38.669 C 379.877 41.217,381.615 55.051,371.648 59.784 C 367.513 61.748,384.314 61.568,205.013 61.568 L 44.602 61.568 42.718 60.951 C 35.349 58.537,31.459 51.146,34.407 45.158 C 36.168 41.582,39.610 38.833,42.907 38.370 C 46.066 37.926,367.082 38.221,368.995 38.669 M361.463 113.356 C 364.374 113.491,367.602 113.777,368.636 113.993 C 382.242 116.830,381.128 134.576,367.224 136.489 C 364.751 136.830,46.589 136.859,43.935 136.519 C 41.206 136.170,39.335 135.402,37.755 133.984 C 29.238 126.334,33.350 115.645,45.634 113.503 C 47.666 113.148,63.901 113.107,198.157 113.113 C 280.794 113.117,348.475 113.046,348.560 112.955 C 348.711 112.794,350.521 112.850,361.463 113.356 M261.954 189.282 C 378.411 189.345,369.755 189.233,372.811 190.713 C 380.554 194.461,379.442 208.429,371.212 210.811 C 368.692 211.539,363.003 211.574,257.841 211.508 L 149.743 211.440 148.055 210.640 C 136.036 204.944,140.764 188.172,154.113 189.150 C 154.679 189.191,203.207 189.251,261.954 189.282 "
          stroke="none"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
