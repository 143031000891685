import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { ArtSpaceLogo } from "../../../theme/ArtSpaceLogo";
import RenderContent from "../RenderContent";
import { SocialLinks } from "../SocialLinks";
import { GravityForm, gravityFormExtractor } from "../gravity-form";
import { useAllGravityForms } from "../../hooks";
import "./Footer.scss";

const Footer = ({ data: { siteSettings } }) => {
  const currentYear = new Date().getFullYear();
  const { footerTagline, artCollectiveContent } = siteSettings.options;

  const gravityFormsData = useAllGravityForms();
  const formId = 1;
  const formData = gravityFormExtractor(formId, gravityFormsData);

  return (
    <footer className="footer">
      <div className="artspace-collective">
        <div className="footer-wrap">
          <div className="left">
            <div className="image-container">
              <ArtSpaceLogo />
            </div>
          </div>
          <div className="right">
            {<RenderContent content={artCollectiveContent} />}
          </div>
        </div>
      </div>

      {gravityFormsData && (
        <div className="newsletter-form">
          <div className="footer-wrap">
            <GravityForm
              formData={formData}
              dataLayerEvent={"form_submit_success"}
            />
          </div>
        </div>
      )}

      <div className="copyright-socials">
        <div className="footer-wrap">
          <div className="copyright">
            <span>
              ©{currentYear} {footerTagline}
            </span>
          </div>
          {siteSettings.options.socialLinksFooter && <SocialLinks />}
        </div>
      </div>
    </footer>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            artCollectiveLogo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            artCollectiveContent
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={(data) => <Footer data={data} {...props} />}
  />
);
